<template>
  <LiefengContent>
    <template v-slot:title>服务清单</template>
    <template v-slot:toolsbarRight>
      <Form :inline="true">
        <FormItem style="margin-bottom: 0">
          <Input
            v-model.trim="searchVal"
            placeholder="通过名称查询"
            search
            enter-button="查询"
            style="width: 280px"
            @on-search="hadlePageSize"
          ></Input>
        </FormItem>
      </Form>
      <Button type="primary" @click="addModalFn">新增服务清单</Button>
      <Button type="primary">服务分类</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :hidePage="true"
      ></LiefengTable>

      <!-- 新增/修改 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        @input="addChangetatusFn"
        width="60%"
        height="calc(100vh - 200px)"
      >
        <template v-slot:contentarea>
          <Form :label-width="100" :rules="validateObj" label-colon>
            <FormItem label="服务名称" prop="itemName">
              <Input
                v-model.trim="addChangeData.itemName"
                placeholder="请输入服务名称"
              ></Input>
            </FormItem>
            <FormItem label="服务简介" prop="introduction">
              <Input
                v-model="addChangeData.introduction"
                type="textarea"
                placeholder="请输入服务简介"
              />
            </FormItem>
            <FormItem>
              <span slot="label" class="validate">服务图片:</span>
              <LiefengUpload
                ref="liefengUpload"
                :showView="true"
                :showDel="true"
              ></LiefengUpload>
            </FormItem>
            <FormItem label="服务单价" prop="price">
                <InputNumber v-model="addChangeData.price" :min='0' controls-outside></InputNumber>
            </FormItem>
            <FormItem label="单价单位" prop="priceUnit">
                <Input
                v-model="addChangeData.priceUnit"
                type="textarea"
                placeholder="请输入单价单位"
              />
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="addChangetatusFn(false)"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChangeFn">{{
            currentSubmitStatus ? "正在保存..." : "保存"
          }}</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/servicelistindex')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import LiefengUpload from "@/components/LiefengUpload";
export default {
  data() {
    return {
      talbeColumns: [
        {
          type: "index",
          width: 60,
          align: "center",
        },
        {
          title: "服务名称",
          minWidth: 200,
          align: "center",
          key: "itemName",
        },
        {
          title: "服务简介",
          minWidth: 200,
          align: "center",
          key: "introduction",
        },
        {
          title: "服务图片",
          width: 120,
          align: "center",
          key: "imgUrl",
          render: (h, params) => {
            if (params.row.imgUrl) {
              return h(
                "div",
                {
                  style: {
                    textAlign: "center",
                    padding: "10px 0",
                    display: "inline-block",
                    cursor: "pointer",
                  },
                },
                [
                  h(
                    "a",
                    {
                      attrs: {
                        target: "view_window",
                        href: params.row.imgUrl,
                      },
                    },
                    [
                      h("img", {
                        attrs: {
                          src: params.row.imgUrl,
                        },
                        style: {
                          width: "80px",
                          height: "80px",
                        },
                      }),
                    ]
                  ),
                ]
              );
            } else {
              return h("div");
            }
          },
        },
        {
          title: "服务分类",
          width: 180,
          align: "center",
          key: "serviceTypeId",
          render: (h, params) => {
            return h("div", this.filterType(params.row.serviceTypeId));
          },
        },
        {
          title: "服务单价",
          width: 140,
          align: "center",
          key: "price",
        },
        {
          title: "单价单位",
          width: 140,
          align: "center",
          key: "priceUnit",
        },
        {
          title: "创建时间",
          width: 180,
          align: "center",
          key: "gmtCreate",
          render: (h, params) => {
            return h("div", this.filterTime(params.row.gmtCreate));
          },
        },
        {
          title: "操作",
          width: 140,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.addChangeTitle = "修改服务清单";
                      this.addChangeData = params.row;
                      this.addChangeStatus = true;
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      page: 1,
      total: 0,
      pageSize: 20,
      loading: true,
      searchVal: "",

      serviceTypeList: [],
      addChangeTitle: "",
      addChangeStatus: false,
      addChangeData: {},
      currentSubmitStatus: false,

      validateObj: {
        itemName: [
          { required: true, message: "请输入服务名称", trigger: "blur" },
        ],
        introduction: [
          { required: true, message: "请输入服务简介", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入服务单价", trigger: "blur" },
        ],
        priceUnit: [
          { required: true, message: "请输入单价单位", trigger: "blur" },
        ],
      },
      uploadImg: []
    };
  },
  methods: {
    saveAddChangeFn() {},
    //   新增
    addModalFn() {
      this.addChangeTitle = "新增服务清单";
      this.addChangetatusFn(true);
    },
    addChangetatusFn(status) {
      this.addChangeStatus = status;
      if (!status) {
        this.addChangeData = {};
      }
    },
    async hadlePageSize() {
      this.loading = true;
      let res = await this.$get(
        "/gateway/api/homecare/pc/OrderOrgItem/selectOrderByOrgCode",
        {
          orgCode: "1",
          keyword: this.searchVal,
        },
        {
          Authorization:
            "bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIxNzcyNDI1NTk5MSIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2MjQ0NzAyMTksImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiJlMTZmNmYxOS1kYzkzLTQ1ZTMtYmZhMC02ZDZkOGNhM2FhZDMiLCJjbGllbnRfaWQiOiJzdWlhbmp1IiwidG9rZW4iOiJzdWlhbmp1bG9naW5fdG9rZW5fODUwMGViNzJhZDUxNDdlMDhlMGRhYTkzOTI0MDM4ZWUifQ.S-BXVH-TUBqKFgo9cmBWGs3GKge93zRhrnW2yok2VDpjGdy2E8NxfX2fAm4CzV3F4C05_ifjP41494RUIyKdG9GtV4Tb7_77byZlwrdPENUuR5AninG7cDOzl028nukbZNKzGje5qloYY1Re5C44t-sUmkOmzNQMzvuRO4JBoaw",
        }
      );
      this.loading = false;
      if (res.code == 200 && res.dataList) {
        this.tableData = res.dataList;
      } else {
        this.$Message.error({
          background: true,
          content:
            res.desc && res.desc.length < 20
              ? res.desc
              : "获取数据失败，请联系管理员处理",
        });
      }
    },
    async getServiceTypeList() {
      let res = await this.$get(
        "/gateway/api/homecare/pc/OrderOrgItem/selectServiceTypeByOrgCode",
        {
          orgCode: "1",
        },
        {
          Authorization:
            "bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIxNzcyNDI1NTk5MSIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2MjQ0NzAyMTksImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiJlMTZmNmYxOS1kYzkzLTQ1ZTMtYmZhMC02ZDZkOGNhM2FhZDMiLCJjbGllbnRfaWQiOiJzdWlhbmp1IiwidG9rZW4iOiJzdWlhbmp1bG9naW5fdG9rZW5fODUwMGViNzJhZDUxNDdlMDhlMGRhYTkzOTI0MDM4ZWUifQ.S-BXVH-TUBqKFgo9cmBWGs3GKge93zRhrnW2yok2VDpjGdy2E8NxfX2fAm4CzV3F4C05_ifjP41494RUIyKdG9GtV4Tb7_77byZlwrdPENUuR5AninG7cDOzl028nukbZNKzGje5qloYY1Re5C44t-sUmkOmzNQMzvuRO4JBoaw",
        }
      );
      if (res.code == 200 && res.dataList) {
        this.serviceTypeList = res.dataList;
      }
    },
    filterTime(val) {
      if (val) {
        return this.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
      } else {
        return "";
      }
    },
    filterType(val) {
      if (val) {
        for (let k in this.serviceTypeList) {
          if (val == this.serviceTypeList[k].serviceTypeId) {
            return this.serviceTypeList[k].name;
          }
        }
      } else {
        return "";
      }
    },
  },
  async created() {
    await this.getServiceTypeList();
    this.hadlePageSize();
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    LiefengUpload,
  },
};
</script>

<style>
</style>